<template>
  <v-row justify="center">
    <v-dialog
      v-model="previewCsvDialogState"
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="headline float-left">{{ $t('message.document_preview') }}</span>

          <div class="actions">
            <button data-toggle="tooltip" data-placement="top" :title="$t('message.download')" @click="downloadDocument(document)"><i class="fas fa-download"></i></button>
            <button data-toggle="tooltip" data-placement="top" :title="$t('message.close')" @click="closePreviewCsvDialog()"><i class="fas fa-times"></i></button>
          </div>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="errors.length">
              <v-alert
                text
                dense
                type="error"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error.title }}
              </v-alert>
            </p>

            <div class="pdf-preview">
              <div v-if="loading === false">
                <table class="table table-striped">
                  <tbody>
                    <tr v-for="(row, keyRow) in rows" :key="keyRow">
                      <td v-for="(col, keyCol) in row" :key="keyCol"> {{ col }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import FileSaver from 'file-saver'
import config from '../../../../config'
import papaparse from 'papaparse'

export default {
  name: 'PreviewCsvDialog',
  data: () => ({
    documentToPreview: null,
    errors: [],
    page: 1,
    currentPage: 0,
    pageCount: 0,
    loading: false,
    rows: []
  }),
  props: {
    document: Object,
    previewCsvDialogState: Boolean
  },
  created () {
    if (this.document) {
      this.previewDocument(this.document)
    }
  },
  methods: {
    previewDocument (document) {
      if (!document) {
        return
      }

      const contentTypeCsv = ['text/csv']

      if (!contentTypeCsv.includes(document.mimeType)) {
        if (document.read !== true) {
          this.putDocument(document)
        }

        this.errors.push({
          title: this.$t('message.document_not_previewable')
        })

        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            preview: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            const responseData = response.data
            const lines = papaparse.parse(responseData)

            this.rows = lines.data
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = headers['content-name']
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)
          }
        })
        .catch(e => {
        })
    },
    putDocument (document) {
      this.successMessage = null
      this.errors = []

      const data = {
        id: this.document.id,
        read: true,
        vault: this.$store.getters.getCurrentVault.id
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents/' + this.document.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400) {
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.document_update_error')
            })
          }
        })
    },
    closePreviewCsvDialog () {
      this.document.read = true

      this.$emit('closePreviewCsvDialog', false)
    }
  }
}
</script>
