<template>
  <v-row justify="center">
    <v-dialog
      v-model="previewImgDialogState"
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="headline float-left">{{ $t('message.document_preview') }}</span>

          <div class="actions">
            <button data-toggle="tooltip" data-placement="top" :title="$t('message.download')" @click="downloadDocument(document)"><i class="fas fa-download"></i></button>
            <button data-toggle="tooltip" data-placement="top" :title="$t('message.close')" @click="closePreviewImgDialog()"><i class="fas fa-times"></i></button>
          </div>
        </v-card-title>
        <v-container>
          <v-card-text>
            <p v-if="errors.length">
              <v-alert
                text
                dense
                type="error"
                v-for="(error, index) in errors"
                :key="index"
              >
                {{ error.title }}
              </v-alert>
            </p>

            <div class="pdf-preview">
              <div v-if="loading === false">
                <img :src="documentToPreview ? documentToPreview : ''">
              </div>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import FileSaver from 'file-saver'
import config from '../../../../config'

export default {
  name: 'PreviewImgDialog',
  data: () => ({
    documentToPreview: null,
    errors: [],
    page: 1,
    currentPage: 0,
    pageCount: 0,
    loading: false
  }),
  props: {
    document: Object,
    previewImgDialogState: Boolean
  },
  created () {
    if (this.document) {
      this.previewDocument(this.document)
    }
  },
  methods: {
    previewDocument (document) {
      if (!document) {
        return
      }

      const contentTypeImg = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/gif', 'image/bmp', 'image/vnd.microsoft.icon', 'image/tiff', 'image/webp']

      if (!contentTypeImg.includes(document.mimeType)) {
        this.errors.push({
          title: this.$t('message.document_not_previewable')
        })

        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            preview: true
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            const reader = new FileReader()

            reader.addEventListener('loadend', () => {
              this.documentToPreview = reader.result
            })

            reader.readAsDataURL(blob)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = headers['content-name']
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)
          }
        })
        .catch(e => {
        })
    },
    putDocument (document) {
      this.successMessage = null
      this.errors = []

      const data = {
        id: this.document.id,
        read: true,
        vault: this.$store.getters.getCurrentVault.id
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/documents/' + this.document.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400) {
          }

          if (response.status === 500) {
            this.errors.push({
              title: this.$t('message.document_update_error')
            })
          }
        })
    },
    closePreviewImgDialog () {
      this.document.read = true

      this.$emit('closePreviewImgDialog', false)
    }
  }
}
</script>
